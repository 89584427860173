import IntlMessages from 'util/IntlMessages';

const myValidator =
  ({ minLength, maxLength, min, max, maxCount, typeComponent }) =>
  (_, value) => {
    return new Promise((resolve, reject) => {
      if (typeComponent === 'tag') {
        const tagMaxLengthValidate = value.some(tag => tag.length > maxLength);
        const tagMinLengthValidate = value.some(tag => tag.length < minLength);

        if (tagMinLengthValidate) {
          reject(<IntlMessages id="dynamic.form.minLength.message" values={{ minLength }} />);
        }
        if (tagMaxLengthValidate) {
          reject(<IntlMessages id="dynamic.form.maxLength.message" values={{ maxLength }} />);
        }
        if (maxCount && value.length > maxCount) {
          reject(<IntlMessages id="dynamic.form.maxCount.message" values={{ maxCount }} />);
        }
      } else {
        if (minLength && value && value.length < minLength) {
          reject(<IntlMessages id="dynamic.form.minLength.message" values={{ minLength }} />);
        } else if (maxLength && value && value.length > maxLength) {
          reject(<IntlMessages id="dynamic.form.maxLength.message" values={{ maxLength }} />);
        } else if (min && value && value < min) {
          reject(<IntlMessages id="dynamic.form.min.message" values={{ min }} />);
        } else if (min && value && value > max) {
          reject(<IntlMessages id="dynamic.form.max.message" values={{ max }} />);
        } else if (maxCount && value && value.length > maxCount) {
          reject(<IntlMessages id="dynamic.form.maxCount.message" values={{ maxCount }} />);
        }
      }

      resolve();
    });
  };

// PROPS
const getSelectProps = component => ({
  options: component.componentProps.options,
  value: Array.isArray(component?.selectValue)
    ? component.selectValue.filter(item => item !== null)
    : [],
});

const getTextProps = component => {
  return {
    autocomplete: component.componentProps.autocomplete ?? false,
    placeholder: component.componentProps.placeholder ?? null,
    validator: myValidator({
      minLength: component.componentProps.minLength,
      maxLength: component.componentProps.maxLength,
    }),
    value: component?.textValue ?? '-',
  };
};

const getNumberProps = component => {
  return {
    validator: myValidator({
      min: component.componentProps.min,
      max: component.componentProps.max,
    }),
    value: component?.numberValue,
  };
};

const getTagProps = component => {
  return {
    validator: myValidator({
      minLength: component.componentProps.minLength,
      maxLength: component.componentProps.maxLength,
      maxCount: component.componentProps.maxCount,
      typeComponent: 'tag',
    }),
    value: Array.isArray(component?.tagValue)
      ? component.tagValue.filter(item => item !== null)
      : [],
  };
};

const getToggleProps = component => {
  return {
    valuePropName: 'checked',
    checked: component.componentProps.checked ?? false,
    value: component?.booleanValue ?? false,
  };
};

const getDateProps = component => {
  return {
    includesTime: component.componentProps.includesTime ?? false,
    value: component?.dateValue,
  };
};

const componentProps = {
  select: component => getSelectProps(component),
  text: component => getTextProps(component),
  number: component => getNumberProps(component),
  tag: component => getTagProps(component),
  toggle: component => getToggleProps(component),
  date: component => getDateProps(component),
};

export const dynamicFormFactory = form => {
  const { components } = form;
  return components.map(component => ({
    id: component?.id,
    type: component?.type,
    order: component?.order,
    label: component?.label?.name,
    description: component?.label?.description,
    required: component?.commonProps?.required,
    ...(componentProps[component?.type] ? componentProps[component?.type](component) : {}),
  }));
};
