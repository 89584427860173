import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import PropTypes from 'prop-types';
import { DatePicker, Select, Row, Col } from 'antd';

import { db } from 'firebase/firebase';
import { collection, orderBy, query } from 'firebase/firestore';
import { useFirestoreQuery, useFirestoreQueryBatched } from 'packages/utils';
import IntlMessages from 'util/IntlMessages';
import FormSelect from 'components/Form/components/FormSelect';
import LabelValue from '../../../../components/LabelValue';

const TimelineFilters = ({ onFiltersChange, toBeClean }) => {
  const [selectedEventTypes, setSelectedEventTypes] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const orgId = useSelector(state => state.organizations.organization.id);
  const selectedDivsIds = useSelector(({ divisions }) => divisions.selector.ids);

  const queriesList = selectedDivsIds.map(divId =>
    query(
      collection(db, 'organizations', orgId, 'divisions', divId, 'event_types'),
      orderBy('createdAt'),
    ),
  );

  const { data: eventTypes } = useFirestoreQueryBatched(queriesList, [selectedDivsIds]);

  const { data: users } = useFirestoreQuery(
    query(collection(db, 'organizations', orgId, 'users'), orderBy('displayName', 'asc')),
    [orgId],
  );

  const onChangeEventType = values => {
    const list = (values || []).slice(0, 10);
    const eventTypesDocs = eventTypes.filter(i => values.includes(i.id));
    setSelectedEventTypes(list);
    onFiltersChange({
      dates: selectedDates,
      user: selectedUser,
      eventTypes: eventTypesDocs,
    });
  };

  const onChangeUser = value => {
    setSelectedUser(value);
    const eventTypesDocs = eventTypes.filter(i => selectedEventTypes.includes(i.id));
    onFiltersChange({
      dates: selectedDates,
      user: value,
      eventTypes: eventTypesDocs,
    });
  };

  const onChangeDates = values => {
    setSelectedDates(values || []);
    const eventTypesDocs = eventTypes.filter(i => selectedEventTypes.includes(i.id));
    onFiltersChange({
      dates: values || [],
      user: selectedUser,
      eventTypes: eventTypesDocs,
    });
  };

  const clearAll = useCallback(() => {
    setSelectedEventTypes([]);
    setSelectedDates([]);
    setSelectedUser(null);
    onFiltersChange({
      dates: [],
      division: null,
      user: null,
      eventTypes: [],
    });
  }, [onFiltersChange]);

  useEffect(() => {
    if (toBeClean) {
      clearAll();
    }
  }, [clearAll, toBeClean]);

  return (
    <>
      <Row justify="start" gutter={[8.8]}>
        <Col xs={24} md={8} lg={8} xl={7} xxl={8}>
          <LabelValue
            vertical
            name={<IntlMessages id="timeline.event.form" values={{ count: '10 max' }} />}
            value={
              <FormSelect
                allowClear
                mode="multiple"
                maxLength={10}
                maxTagCount={2}
                onChange={onChangeEventType}
                value={selectedEventTypes}
                options={eventTypes}
              />
            }
          />
        </Col>
        <Col xs={24} md={8} lg={8} xl={7} xxl={8}>
          <LabelValue
            name={<IntlMessages id="timeline.filter.dateRange" />}
            vertical
            value={
              <DatePicker.RangePicker
                value={selectedDates}
                className="gx-w-100"
                ranges={{
                  Today: [moment().startOf('day'), moment().endOf('day')],
                  'This Month': [moment().startOf('month'), moment().endOf('month')],
                }}
                showTime
                format="YYYY-MM-DD HH:mm"
                onChange={onChangeDates}
                allowClear
              />
            }
          />
        </Col>
        <Col xs={24} md={8} lg={8} xl={7} xxl={6}>
          <LabelValue
            name={<IntlMessages id="timeline.filter.author" />}
            vertical
            value={
              <Select
                allowClear
                className="gx-w-100"
                mode="single"
                onChange={onChangeUser}
                placeholder="Choose an user"
                value={selectedUser}
              >
                {users?.map(user => (
                  <Select.Option key={user.id} value={user.id}>
                    {`${user?.firstName} ${user?.lastName}`}
                  </Select.Option>
                ))}
              </Select>
            }
          />
        </Col>
      </Row>
    </>
  );
};

TimelineFilters.propTypes = {
  onFiltersChange: PropTypes.func.isRequired,
  toBeClean: PropTypes.bool.isRequired,
};

export default React.memo(TimelineFilters);
