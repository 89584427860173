import { notification } from 'antd';
import { isDev } from 'constants/commons';
import { sendErrorToSentry } from './sentry/utils';

/**
 * Executes a given asynchronous function and handles any errors that occur during its execution.
 * If an error is caught and the application is in development mode, the error is logged to the console.
 * Regardless of the environment, an error notification is displayed to the user.
 *
 * @param {Function} fn - The asynchronous function to be executed safely.
 * @returns {Promise<void>} - A promise that resolves when the function execution is complete.
 *
 * NOTE: In order to capture error , we need to use " throw new Error('Error message') " in the function
 */

const safeExecute = async fn => {
  try {
    await fn();
  } catch (error) {
    if (isDev) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    notification.error({
      message: error.message,
      placement: 'topRight',
    });
    sendErrorToSentry({ message: error.message });
  }
};

export default safeExecute;
